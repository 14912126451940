import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import styled, {css} from 'styled-components'
import {isEmpty} from 'lodash-es'
import {getLangToSlug, media, normalizeId} from '../utils'
import {AppShell, Box, Button, Hero, ProductsContainer, SectionMaterials} from '../components/custom'
import useMediaTier from '../hooks/useMediaTier'
import {ContentContainer} from '../components/base'
import WpContent, {DefaultWrapper} from '../components/base/WpContent/lazy'
import SSRSuspense from '../components/custom/SSRSuspense/SSRSuspense'


const StyledPortfoliosWrapper = styled.div`
  display: block;

  > :first-child {
    padding-top: 9.375rem;
  }
`

const StyledContentContainer = styled(ContentContainer)`
  padding-bottom: 0;
`

const StyledPortfolioBox = styled(Box)`
  align-items: center;
`

const StyledPortfolioBoxFooter = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3.375rem;

  ${media.down('mobile')(css`
    &:last-child {
      justify-self: center;
    }
  `)}

  ${media.down('mobile')(css`
    margin-top: 1.25rem;
  `)}
`

const StyledMaterialButton = styled(Button)`
  &:not(:first-child) {
    margin-left: 1.5rem;
  }
`


const Portfolio = ({data, pageContext: {lang}}) => {
  const {mediaMatches: isMobile} = useMediaTier('mobile')
  const {title, featuredImage, content, seo, translations, uri, template: {portfolios: {
    portfolioMaterials, selectedPortfolios, allMaterialsButton, catalogueLink,
  }}} = data.wpPage
  const fallBack = (<DefaultWrapper>{content}</DefaultWrapper>)

  const isOdd = (number) => {
    return number % 2 === 1
  }

  return (
    <AppShell title={title} seo={seo} lang={lang} langToSlug={getLangToSlug({translations, uri, lang})}>
      <Hero
          url={featuredImage?.node?.localFile?.url}
          title={title}
          buttonTitle={catalogueLink?.title}
          to={catalogueLink?.url}
      />
      <If condition={Boolean(content)}>
        <StyledContentContainer>
          <SSRSuspense fallback={fallBack}>
            <WpContent>
              {content}
            </WpContent>
          </SSRSuspense>
        </StyledContentContainer>
      </If>
      <If condition={!isEmpty(selectedPortfolios)}>
        <StyledPortfoliosWrapper>
          <For each="portfolio" of={selectedPortfolios} index="idx">
            <If condition={portfolio.portfolio}>
              <ProductsContainer id={normalizeId(portfolio?.portfolio.title)} key={idx} inverted={isOdd(idx)}>
                <StyledPortfolioBox
                    reversed={isOdd(idx) && !isMobile}
                    title={portfolio?.portfolio.title}
                    description={portfolio?.portfolio.content}
                    image={portfolio.portfolio.featuredImage?.node}
                    footer={
                      <StyledPortfolioBoxFooter>
                        <If condition={
                          !isEmpty(portfolio.portfolio.portfolio?.eshopLink?.url) &&
                        !isEmpty(portfolio.portfolio.portfolio?.eshopLink?.title)}
                        >
                          <Button inverted={isOdd(idx)} to={portfolio.portfolio.portfolio?.eshopLink?.url}>
                            {portfolio.portfolio.portfolio?.eshopLink?.title}
                          </Button>
                        </If>
                        <If condition={
                          !isEmpty(portfolio.portfolio.portfolio?.buttonMaterial?.url) &&
                        !isEmpty(portfolio.portfolio.portfolio?.buttonMaterial?.title)}
                        >
                          <StyledMaterialButton
                              type="text"
                              inverted={isOdd(idx)}
                              to={portfolio.portfolio.portfolio?.buttonMaterial?.url}
                          >
                            {portfolio.portfolio.portfolio?.buttonMaterial?.title}
                          </StyledMaterialButton>
                        </If>
                      </StyledPortfolioBoxFooter>
                    }
                />
              </ProductsContainer>
            </If>
          </For>
        </StyledPortfoliosWrapper>
      </If>
      <If condition={!isEmpty(portfolioMaterials)}>
        <SectionMaterials materials={portfolioMaterials} allMaterialsButton={allMaterialsButton} />
      </If>
    </AppShell>
  )
}

export const data = graphql`
  query PortfolioPage($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      translations {
        href
        locale
      }
      uri
      content
      seo {
        metaDesc
        opengraphDescription
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          mediaItemUrl
        }
        twitterImage {
          mediaItemUrl
        }
      }
      featuredImage {
        node {
          localFile {
            url
          }
        }
      }
      template {
        ... on WpTemplate_Portfolio {
          portfolios {
            catalogueLink {
              title
              url
            }
            portfolioMaterials {
              material {
                ... on WpMaterial {
                  title
                  materials {
                    referenceImage {
                      altText
                      srcSet
                    }
                  }
                }
              }
            }
            selectedPortfolios {
              portfolio {
                ... on WpPortfolio {
                  title
                  content
                  featuredImage {
                    node {
                      srcSet
                      altText
                    }
                  }
                  portfolio {
                    material {
                      ... on WpMaterial {
                        id
                        uri
                        title
                      }
                    }
                    eshopLink {
                      url
                      title
                    }
                    buttonMaterial {
                      url
                      title
                    }
                  }
                }
              }
            }
            allMaterialsButton {
              title
              url
            }
          }
        }
      }
    }
  }
`

Portfolio.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
}

export default Portfolio
