
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'
import {ContentContainer} from '../../base'


const StyledContainer = styled(ContentContainer)`
  ${({inverted}) => inverted && css`
    background: linear-gradient(to right, transparent 65% , ${({theme}) => theme && theme.colors.background} 35%);
    color: ${({theme}) => theme.colors.textInverted};

    ${media.down('mobile')(css`
      background: transparent;
    `)}
  `}
`

const PortfolioContainer = ({inverted, children, ...props}) => (
  <StyledContainer inverted={inverted} {...props}>
    {children}
  </StyledContainer>
)

PortfolioContainer.propTypes = {
  inverted: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default PortfolioContainer
