
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {isEmpty} from 'lodash-es'
import {media, normalizeId} from '../../../utils'
import {BaseLink, Typography} from '../../base'
import ContentContainer from '../../base/ContentContainer/ContentContainer'
import Image from '../../base/Image/Image'
import Button from '../Button/Button'


const StyledMaterialSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: ${({theme}) => theme.colors.background};

  ${media.down('mobile')(css`
    justify-content: center;
  `)}
`

const StyledMaterialLink = styled(BaseLink)`
  margin: 0 2rem;
  cursor: pointer;
`

const StyledImageContainer = styled.div`
  margin-top: 1.5rem;
`

const StyledImage = styled(Image)`
  transition: ${({theme}) => `transform ${theme.global.duration} ease-in`};
  z-index: 1;
  width: 17rem;
  height: 100%;

  &:hover {
    transform: translateY(-8%);
  }
`

const StyledShadow = styled.div`
  position: relative;
  transition: opacity ${({theme}) => `${theme.global.duration} ease-in`};
  opacity: 0;

  ${StyledImageContainer}:hover &,
  focus & {
    opacity: 1;
  }

  &::after {
    position: absolute;
    bottom: 1.25rem;
    left: 1.35rem;
    z-index: 0;
    border-radius: 35%;
    box-shadow: 0 18px 9px 0 rgba(2, 2, 2, 0.4);
    width: 13rem;
    height: 0.5rem;
    content: '';

    ${media.down('mobile')(css`
      width: 13rem;
      left: 0;
      right: 7%;
      margin-left: auto;
      margin-right: auto;
    `)}
  }
`

const StyledTitle = styled(Typography)`
  margin: 1.875rem 0 2.5rem;
  color: ${({theme}) => theme.colors.secondary};
`

const StyledArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2rem;
  width: 17rem;
`

const StyledLink = styled(Button)`
  display: flex;

  svg {
    margin: 0 0.5rem 0 -0.5rem;
  }

  path,
  rect {
    fill: ${({theme}) => theme.colors.secondary};
  }

  ${media.down('mobile')(css`
    margin-top: 2rem;
  `)}
`

const SectionMaterials = ({materials, allMaterialsButton}) => (
  <If condition={materials}>
    <ContentContainer>
      <StyledMaterialSection>
        <For each="material" of={materials} index="idx">
          <StyledMaterialLink to={`/materialy#${normalizeId(material.material?.title)}`} key={idx}>
            <If condition={!isEmpty(material.material?.materials?.referenceImage?.srcSet)}>
              <StyledImageContainer>
                <StyledImage
                    url={material.material?.materials?.referenceImage?.srcSet}
                    alt={material.material?.materials?.referenceImage?.altText}
                />
                <StyledShadow />
              </StyledImageContainer>
            </If>
            <StyledTitle type="h2" isHeading>
              {material.material?.title}
            </StyledTitle>
          </StyledMaterialLink>
        </For>
        <If condition={!isEmpty(allMaterialsButton?.url) &&
           !isEmpty(allMaterialsButton?.title)}
        >
          <StyledArrowContainer>
            <StyledLink
                type="text"
                to={allMaterialsButton?.url}
            >
              {allMaterialsButton?.title}
            </StyledLink>
          </StyledArrowContainer>
        </If>
      </StyledMaterialSection>
    </ContentContainer>
  </If>
)

SectionMaterials.propTypes = {
  materials: PropTypes.array.isRequired,
  allMaterialsButton: PropTypes.object.isRequired,
}

export default SectionMaterials
